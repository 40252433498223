
import React, { useState, useEffect } from 'react';

import './App.css';

import { getUnitsPer } from './App-web3';
import { getExists } from './App-web3';
import { getCrack } from './App-web3';
import { getOwner } from './App-web3';
import { getSupply } from './App-web3';
import { getOwnBal } from './App-web3';
import { getOwnBalUNF } from './App-web3';
import { getAccnt } from './App-web3';
import { init } from './App-web3';
import { disConnectWC } from './App-web3';
// import { CSSTransition } from 'react-transition-group';
import axios from 'axios';

function App() {
  const [ id , setId ] = useState(0)
  const url =  `https://www.unitcoin.me/uri/${id}.json`
  const [ info , setInfo ] = useState(null)
  const [ pic , setPic ] = useState('')
  const [ tokId , settokId ] = useState(0)
  const [tooltip1, setTooltip1]=useState('');
  const [tooltip2, setTooltip2]=useState('');
   var tokenId = id; 














const register = e => {
  e.preventDefault();
  setPic(info)
  settokId(id)
  console.log(info,"INFO",info.image,"INFOxxxxxxxxxxxxxxx");
  // console.log("INFO",info.image,"INFO");
}






  useEffect(() => {
    axios.get(url)
    .then(res => {
        setInfo(res.data)
            setId(id)   
            console.log("consolLogged the ID", id)
    })

}, [url, id])




//  ------------- Owners Balance ERC-20 -----------------
   
 const [ OwnerBalUNF , setOwnerBalUNF ] = useState('');

 function OnwersBal() {
   
  getOwnBalUNF()
     .then(OwnerBalUNF => {
       setOwnerBalUNF(OwnerBalUNF);
     document.getElementById("OwnerBalText").innerHTML = "";
     })
     .catch(err => {
      document.getElementById("OwnerBalText").innerHTML = " at 0.<br>  Log In to your Wallet's ETH Address & Refresh page";

      return;
     });
     };
     useEffect(() => {OnwersBal() 
    },[]);
    
    
    
    
    
    //-------------   Units per NFT   -----------------
    const [Units_perNFT1, Balance] = useState(false);
    const fetchUnitsPer = () => {
      getUnitsPer()
      .then(Units_perNFT1 =>{
        Balance(Units_perNFT1);
      })
      .catch(err => {
        console.log(err);
        return;
      });
    };
    
    
    
    
    
    // -----------------  Crack it ---------------
    
    const fetchCrack = () => {
      getCrack(tokenId)
      .then(crack =>{
        getCrack(crack);
        console.log(" CRACKED!");
        window.location.reload(true);
      })
      .catch(err => {
        document.getElementById("crack").innerHTML = "Error! No Id selected";
        return;
      });
      
    }
    
    
    // ------------  Owner Of -----------------
    const [ owner , setOwner ] = useState(false);
    
    // Exists 
    const [ exists , setExists ] = useState(true);
    
    const fetchExists = () => {
      
      getExists(tokenId)
      
      .then(result => {
        setExists(result);
        
        if (result === true) {
          console.log(tokenId, 'does exist!');
          document.getElementById("demo").innerHTML = " does exist!";
          
          getOwner(tokenId)
          .then(result => {
            setOwner(result);
            if ( exists === true) {console.log(exists, "from GetExists - Is true!");}
            document.getElementById("ownerQ").innerHTML = "";
            document.getElementById("noId").innerHTML = "";
            console.log(result, "of token ID", tokenId, " is  ", owner);
          });
          
          
        } else {
          if ( exists !== true) {console.log(exists, "from GetExists - Is False!");};
          console.log(id, 'does NOT exist!');
          document.getElementById("demo").innerHTML = " does NOT exist!";
          document.getElementById("ownerIs").innerHTML = "";
          document.getElementById("ownerQ").innerHTML = "--------- Try Again ----------";
          document.getElementById("noId").innerHTML = "";
          setPic('https://www.unitcoin.me/uri/x.json');
          
          
        };
      })
      
      
      .catch(err => {
        console.log(err);
        console.log("you've errored 1");
        document.getElementById("noId").innerHTML = " Error! No Token Entered";
        document.getElementById("signIn").innerHTML = "Please Sign In to Your Wallet";
        document.getElementById("signIn").style={color: "red"};
        return;
      });
      
    };
    
    

    
    // ------------  Total Supply  -----------------
    const [ supply , setSupply ] = useState(false);
    const fetchSupply = () => {
      getSupply()
      .then(result => {
        setSupply(result);
        console.log(result);
        document.getElementById("Supply").innerHTML = "";
      })
      .catch(err => {
        console.log(err);
        console.log("you've errored 2");
        return;
      });
    };
    
    
    
    
    //  -------------   Owners Balance -----------------
    const add = owner;
    const [ OwnerBal , setOwnerBal ] = useState('');
    const fetchOwnerBal = () => {
      getOwnBal(add)
      .then(OwnerBal => {
        setOwnerBal(OwnerBal);
        document.getElementById("OwnerBalZ").innerHTML = "";
      })
      .catch(err => {
        
        return;
      });
    };
    
    useEffect(() => {fetchOwnerBal() 
    });
    
    
    
    // let [show, setShow] = useState(false);
    //   useEffect(() => {
      //     // setTimeout(()=>setShow(true));
      //     setShow(true);
      //     console.log('showIto....')
      //   }, []);
      
      // var OwnAcc
      //   console.log("OwnAcc is app:", OwnAcc);
      
      
      
      //  ------------- Owners Account -----------------
      const [ Own , setOwn ] = useState('');
     
     const Own1 = () => {
        
        getAccnt()
          .then(Own => {
            setOwn(Own);
            console.log("getAccnt", Own)
            document.getElementById("Owner").innerHTML = Own;
          })
          .catch(err => {
           return;
          });
          };
          useEffect(() => {Own1() 
         },[]);
      
      
      
      // ____________________________________ RETURN  _____________________________________________
      
      
      // if((info) !== null && info !== undefined && info !== '' && info !== 'undefined') {
        
        
    
  return (
    <div className="App border">
    <div></div>
    
  
<div>

   {/* <CSSTransition
        in={show}
        delay={1500}
        timeout={1500}
        classNames="slide"
      > */}

        <h1 >UNIT NFT Interact</h1>


<button onClick={() => init()}>Wallet Connect</button>
<button onClick={() => disConnectWC()}>Disconnect Wallet Connect</button>
{/* <button onClick={() => Own1()}>getAccnt</button> */}

<div id='Owner'>Here</div>

  <p >NFT Balance at Metamask Address = &nbsp;{OwnerBal}
        <span id ="OwnerBalZ">
        Check
        </span>
  </p>

<p ><span id = "check">ERC-20 Balance = </span>{OwnerBalUNF}  UNIT's<span id = "OwnerBalText"></span>


<span className ="tip" onClick={()=>setTooltip1(!tooltip1)}>    ? </span>
{tooltip1?<span className = "tipper" onClick={()=>setTooltip1(!tooltip1)}>Wallet balance not including Units held in NFT's. &nbsp;&nbsp;&nbsp;<a href="https://www.unifiedtoken.net/OTC/OTC.html" target="_blank" rel="noopener noreferrer">Acquire UNIT's</a> </span>:null}

</p>
<p></p>

                        
                        <img src = { pic.image } alt= {''} style={{
                        backgroundImage: `url("https://unitcoin.me/uri/img/X.gif")`,
                        
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#ddd',
                        width:'250px', 
                        height:'354px',
                        backgroundSize: 'cover'
                        }}/>
                        
                        <h3 id="signIn" style={{color: "rgb(0, 17, 32, .8)"}} >Enter the Id of your NFT</h3>

                    <form onSubmit={register}>
                        <input 
                        id="userInput" 
                        placeholder="enter id #"
                        type="number"
                        required
                        value = { id || '' }
                        onChange ={(e) => setId(e.target.value)
                          }
                        /> 
                        
                    
                    <p ><span id = "noId">id #</span>{tokId} {exists}<span id ="demo"> Check</span></p>

                    <button onClick= {() => fetchExists()}>Enter</button>

                    </form>

  

  <p>Token Id #{tokId} Owners Address...</p>
    <p><span id="ownerQ">........................................</span> 
    <span id="ownerIs">{owner}</span></p>
  
  <p >NFT id #{tokId}<span id ="crack"> to Crack Open</span>
  
  <span className ="tip" onClick={()=>setTooltip2(!tooltip2)}>    ? </span>
{tooltip2?<span className = "tipper tipper2" onClick={()=>setTooltip2(!tooltip2)}> Once Cracked open the NFT will be burned, depositing the ERC-20 UNIT's to your account. ＼(-_-) /</span>:null}
  
  </p>

   <button id = "crackBtn" onClick={() => fetchCrack()}>Crack it</button>     
      
    <p></p>


    <p> UNIT's per NFT :  {Units_perNFT1}</p>
    <button onClick={() => fetchUnitsPer()}>See Amount</button>
    <p></p>

  

    <p >Supply ={supply}<span id ="Supply"> Check</span></p>
  <button onClick= {() => fetchSupply()}>Check Supply</button>
  <p></p>



</div>
  </div>
  );

  
//  } else {
//   return ( 
//       <div>
//           <h1>
//           Loading...
//           </h1>
//       </div> 
//   )
// }

}

export default App;
  