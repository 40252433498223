import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";
// import './abi.js';

import { erc721Abi } from "./abi";
import { UNFabi } from "./abi-UNF";

let selectedAccount;

let erc721Contract;

let erc20UNFcontract;

let isInitialized = false;

let accountX;


// ...new WC

// var account;
// var WalletConnectProvider;
// let provider;

// https://docs.walletconnect.com/quick-start/dapps/web3-provider
const provider = new WalletConnectProvider({
  rpc: {
	//1: "https://cloudflare-eth.com/", // https://ethereumnodes.com/
	// 4: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161", // https://rpc.info/
	1: "https://mainnet.infura.io/v3/42e767e5441c4cf99330bb09519c2342",
	4: "https://rinkeby.infura.io/v3/42e767e5441c4cf99330bb09519c2342", // https://rpc.info/
	137: "https://polygon-rpc.com/", // https://docs.polygon.technology/docs/develop/network-details/network/
	// ...

  },
  bridge: 'https://bridge.walletconnect.org',

// wc:00e46b69-d0cc-4b3e-b6a2-cee442f97188@1?bridge=https%3A%2F%2Fbridge.walletconnect.org&key=91303dedf64285cbbaf9120f6e9d160a5c8aa3deb67017a3874cd272323f48ae

});

// var init = 0;

export var init = async () => {
  // let provider = window.ethereum;
  // await provider.enable();

  if (provider.connected) {
    console.log('Connected',(provider.connected));
    }else{
      console.log('not Connected',(provider.connected));
    }
  await provider.enable();
    // can comment out this or infura?
   const web3 = new Web3(provider);
   window.w3 = web3
   
   window.ethereum.on('networkChanged', function(networkId){
    console.log('networkChanged',networkId);
    window.location.reload(false);
  
  });
  
  
  //  if (typeof web3 !== 'undefined') { web3 = new Web3(web3.currentProvider);
  //  } else { };
     // set the provider you want from Web3.providers web3 = new Web3(new Web3.providers.HttpProvider("http://localhost:8545")); }
  
  
   //new:
  //  const networkId = await web3.eth.net.getId();
  
  // const web3 = new Web3('https://mainnet.infura.io/v3/42e767e5441c4cf99330bb09519c2342');
  
  erc721Contract = new web3.eth.Contract(
    erc721Abi,
  //  // Mainnet:
    '0xD72FB1b2D2EaFB6C4651228C4794E39147d5fCa3'
  
  // // Rinkeby acc 11 chrome
    // '0xAe35771802cDe63791828b267d1F393cfcb82149'
  // new:
  // erc721Contract.networks[networkId].address
  );
  
  
    // // Rinkeby acc 11 chrome ERC-20 token
  erc20UNFcontract = new web3.eth.Contract(
    UNFabi, '0x8dECd41A5A235d464195224Af177329Ca322bd55'
    // UNFabi, '0x7963014dAf5a8A2b52ed2Bf0123Ce69877ba891A' // Rinkeby
  );
  
  isInitialized = true;
  
  
  accountX = await web3.eth.getAccounts();
  // console.log(accountX[0], "accountX");
  // console.log("accountX", accountX[0]);
  
  // const OwnAcc = accountX[0];
  // console.log("OwnAcc is:", OwnAcc);


  // Get current account info?
  const balanceOft =  await erc721Contract.methods.balanceOf(accountX[0])
  .call()
  console.log(balanceOft, " get current account info");
  
  const totalSupply =  await erc20UNFcontract.methods.totalSupply() 
  .call()
  .then((totalSupply) =>{
    return Web3.utils.fromWei(totalSupply);
  });
  console.log(totalSupply);
  
  };

// ........ End of Init.........


export const disConnectWC = async () => {

  await provider.disconnect()	;
  // document.getElementById("disConnectWC").classList.remove("show");	
  // document.getElementById("WalletConnectButton").classList.remove("hide");
  // document.getElementById("userAddress").innerText = "You've Disconnected from WalletConnect";
  // statusDesc.innerHTML = ""
  // statusBalance.innerHTML = ""
  // statusbalConv.innerHTML = ""
  // document.getElementById("spinner").classList.remove("hide");
  console.log('disconnected WC');
  window.location.reload(false);
  // WCsectionOFF();
  // location.reload();
  
  }


// .................




  
// export const init = async () => {
// let provider = window.ethereum;

// if (typeof provider !== 'undefined'){
//   // Metamask is installed!

//   provider
//     .request({ method: 'eth_requestAccounts' })
//     .then(accounts => {
//     selectedAccount = accounts[0];
//     console.log(`Selected account is ${selectedAccount}`);

//     })
//     .catch((err) =>{
//      console.log(err);
//     });


//   window.ethereum.on('accountsChanged', function (accounts) {
//     selectedAccount = accounts[0];
//     console.log(`Selected account changed to ${selectedAccount}`);
//     console.log("Changed!");
//     window.location.reload(false);
//   });
// }
//   // can comment out this or infura?
//  const web3 = new Web3(provider);

 
//  window.ethereum.on('networkChanged', function(networkId){
//   console.log('networkChanged',networkId);
//   window.location.reload(false);

// });


// //  if (typeof web3 !== 'undefined') { web3 = new Web3(web3.currentProvider);
// //  } else { };
//    // set the provider you want from Web3.providers web3 = new Web3(new Web3.providers.HttpProvider("http://localhost:8545")); }


//  //new:
// //  const networkId = await web3.eth.net.getId();

// // const web3 = new Web3('https://mainnet.infura.io/v3/42e767e5441c4cf99330bb09519c2342');

// erc721Contract = new web3.eth.Contract(
//   erc721Abi,
// //  // Mainnet:
//   '0xD72FB1b2D2EaFB6C4651228C4794E39147d5fCa3'

// // // Rinkeby acc 11 chrome
//   // '0xAe35771802cDe63791828b267d1F393cfcb82149'
// // new:
// // erc721Contract.networks[networkId].address
// );


//   // // Rinkeby acc 11 chrome ERC-20 token
// erc20UNFcontract = new web3.eth.Contract(
//   UNFabi, '0x8dECd41A5A235d464195224Af177329Ca322bd55'
//   // UNFabi, '0x7963014dAf5a8A2b52ed2Bf0123Ce69877ba891A' // Rinkeby
// );

// isInitialized = true;


// accountX = await web3.eth.getAccounts();
// console.log(accountX[0], "accountX");
// console.log("accountX", accountX[0]);


// // Get current account info?
// const balanceOft =  await erc721Contract.methods.balanceOf(accountX[0])
// .call()
// console.log(balanceOft, " get current account info");

// const totalSupply =  await erc20UNFcontract.methods.totalSupply() 
// .call()
// .then((totalSupply) =>{
//   return Web3.utils.fromWei(totalSupply);
// });
// console.log(totalSupply);

// };

// ......  .end of init


// erc-20 token stuff:  ------------------------------
export const getSymbolUNF
= async () => {
  if (!isInitialized) {
    await init();
  };
  return erc20UNFcontract.methods
  .symbol()
  .call()
};




export const getOwnBalUNF = async () => {
  if (!isInitialized) {
    await init();
  };
  return erc20UNFcontract.methods
  .balanceOf(accountX[0])
  .call()
  .then((balanceOf) =>{
    return Web3.utils.fromWei(balanceOf);
  })
      .catch((err) =>{
        console.log(err);
        });
        
};


export const getAccnt = async () => {
  if (!isInitialized) {
    await init();
  };
  return accountX[0];
        
};




// ERC-721 NFT stuff:  ------------------------------

/// NFT balance Of Owner
export const getOwnBal = async () => {
  if (!isInitialized) {
    await init();
  };
  return erc721Contract.methods
  .balanceOf(accountX[0])
  .call()
      .catch((err) =>{
        console.log(err);
        });
};







// Units Per NFT
export const getUnitsPer = async () => {
  if (!isInitialized) {
    await init();
  };
  return erc721Contract.methods
    .Units_perNFT()
    .call()
    .then((Units_perNFT) =>{
      return Web3.utils.fromWei(Units_perNFT);
    });
};



// Get Token URI
export const getTokenURI = async (tokenId) => {
  if (!isInitialized) {
    await init();
  };
  return erc721Contract.methods
    .tokenURI(tokenId)
    .call()
};


/// Owner of
export const getOwner = async (tokenId) => {
  if (!isInitialized) {
    await init();
  };
  return erc721Contract.methods
  .ownerOf(tokenId)
  .call()
};





/// Exists
export const getExists = async (tokenId) => {
  if (!isInitialized) {
    await init();
  };
  return erc721Contract.methods
  .Exists(tokenId)
  .call()
};


/// Total Supply
export const getSupply = async () => {
  if (!isInitialized) {
    await init();
  };
  return erc721Contract.methods
  .totalSupply()
  .call()
  .catch((err) =>{
    console.log(err,"Supply Issue");
    });
};




/// Crack_Open

export const getCrack = async (tokenId) => {
  if (!isInitialized) {
    await init();
    console.log(`Selected account confirmed!! ${selectedAccount}`);
  };
  return erc721Contract.methods
  .CrackOpen(tokenId)
  .send({from: selectedAccount})
  

      .catch((err) =>{
        console.log(err, "Crack caught error!");
        window.location.reload(true);
      });


};


//   MINT

export const getMint = async ( to , tokenURI) => {
  if (!isInitialized) {
    await init();
  };
  return erc721Contract.methods
  .Mint(to , tokenURI)
  .send({from: selectedAccount})
  .catch((err) =>{
    console.log( err);
   });
};


