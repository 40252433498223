export const erc721Abi = 
[
  {
    "inputs": [{
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {
        "internalType": "string",
        "name": "tokenURI",
        "type": "string"
    }],
    "name": "Mint",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
                {
                  "inputs": [{
                      "internalType": "address",
                      "name": "owner",
                      "type": "address"
                  }],
                  "name": "balanceOf",
                  "outputs": [{
                      "internalType": "uint256",
                      "name": "",
                      "type": "uint256"
                  }],
                  "stateMutability": "view",
                  "type": "function"
                },


{
  "inputs": [],
  "name": "totalSupply",
  "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
},

  

  {
      "inputs": [{
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
      }],
      "name": "CrackOpen",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
  },


  {
      "inputs": [{
          "internalType": "uint256",
          "name": "tokens_",
          "type": "uint256"
      }],
      "name": "e_AirDrop",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
  },


  

{
  "inputs": [],
  "name": "Units_perNFT",
  "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
},    



{
  "inputs": [],
  "name": "symbol",
  "outputs": [{
      "internalType": "string",
      "name": "",
      "type": "string"
  }],
  "stateMutability": "view",
  "type": "function"
},



{
  "inputs": [{
      "internalType": "uint256",
      "name": "tokenId",
      "type": "uint256"
  }],
  "name": "ownerOf",
  "outputs": [{
      "internalType": "address",
      "name": "",
      "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
},


{
  "inputs": [],
  "name": "name",
  "outputs": [{
      "internalType": "string",
      "name": "",
      "type": "string"
  }],
  "stateMutability": "view",
  "type": "function"
},




{
  "inputs": [],
  "name": "decimals",
  "outputs": [{
      "internalType": "uint8",
      "name": "",
      "type": "uint8"
  }],
  "stateMutability": "view",
  "type": "function"
},





{
  "inputs": [{
      "internalType": "uint256",
      "name": "tokenId",
      "type": "uint256"
  }],
  "name": "Exists",
  "outputs": [{
      "internalType": "bool",
      "name": "",
      "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
},


{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "tokenId",
      "type": "uint256"
    }
  ],
  "name": "tokenURI",
  "outputs": [
    {
      "internalType": "string",
      "name": "",
      "type": "string"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},


{
  "inputs": [],
  "name": "symbol",
  "outputs": [
    {
      "internalType": "string",
      "name": "",
      "type": "string"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},

{
  "inputs": [{
      "internalType": "address",
      "name": "owner",
      "type": "address"
  }, {
      "internalType": "uint256",
      "name": "index",
      "type": "uint256"
  }],
  "name": "tokenOfOwnerByIndex",
  "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
},

{
  "inputs": [{
      "internalType": "uint256",
      "name": "index",
      "type": "uint256"
  }],
  "name": "tokenByIndex",
  "outputs": [{
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
},


]